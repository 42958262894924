<template>
<v-app>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  :form="form"
                  :title="'Tambah Virtual Event'"
                  route="api/pitching-events"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/pitching-events/Form.vue";
import moment from 'moment';

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        start_time: "",
        end_time: "",
        start_pitching: "",
        end_pitching: "",
        status_id: "",
        image_header: "/img/default/classroom_h.png",
        image_thumbail: "/img/default/classroom_t.png",
        status_id: 1
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Virtual Event", route: "/pitching-events" },
      { title: "Tambah" },
    ]);

    this.form.start_time = moment().format('YYYY-MM-DD HH:mm:ss');
    this.form.end_time = moment().add(2, 'hours').format('YYYY-MM-DD HH:mm:ss');
    this.form.start_pitching = moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    this.form.end_pitching = moment().add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');

    console.log('form', this.form)
  },
};
</script>